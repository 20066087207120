import * as React from 'react'
import { Colors } from '../../theme'
import { Box, CircularProgress } from '@mui/material'

interface IconProps {
  color?: string | undefined
  name: string
}
export default function TIcon({ color, name }: IconProps) {
  return (
    <i className={`icon ${name}`} style={{ fontSize: 24, color: color ? color : Colors.primary }} />
  )
}

export function Loading() {
  return (
    <Box sx={{ my: 10, justifyContent: 'center', display: 'flex' }}>
      <CircularProgress size={40} />
    </Box>
  )
}
