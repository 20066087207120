import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { Formik } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { AppBar, DatePicker, FileInput, Icon, Loading } from '../../components'
import { updateSnackbarState } from '../../redux'
import Storage, { USER_PROFILE } from '../../services/Storage'
import {
  UserProductPostPut,
  UserProductApi,
  EUserProductType,
  ERepairshopType,
  ProductApi,
  CustomerProfile,
  UserProductDetails,
  EProductType,
} from '../../services/Swagger/Services'
import { Colors } from '../../theme'
import { useAddBike } from './useAddBike'
import { getBase64 } from '../../services/Helper'
import { useTranslation } from 'react-i18next'
import yup from '../../services/Translations/yup'
import { useQuery } from '@tanstack/react-query'
import { red } from '@mui/material/colors'

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '8px 10px',
  color: theme.palette.primary.main,
  backgroundColor: 'white',
}))

const GroupItems = styled('ul')({
  padding: 0,
})

function Add() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const userProductId = searchParams.get('id')
  const bikeSchema = yup.object({
    productId: yup.number().required(),
    brandId: yup.number().required(),
    categoryId: yup.number().required(),
    frameNumber: yup
      .string()
      .matches(/^[Ww][Bb][Ll].*$/, t('frameNumberError'))
      .required(),
    purchasedPlaceId: yup.number().required(),
    billPhoto: yup.string().required(),
    framePhotos: yup.array().of(yup.string()).min(1).required(),
  })
  const user: CustomerProfile = Storage.getItem(USER_PROFILE)
  const { brands, categories, getRepairShops, getListBikes, isLoading } = useAddBike()
  const dispatch = useDispatch()
  const [isSubmit, setIsSubmit] = useState(false)
  const [showMessage, setShowMessage] = useState(true)
  const [showSerialNumber, setShowSerialNumber] = useState(false)

  const [product, setProduct] = useState<UserProductPostPut & { brandId: number }>({
    brandId: 0,
    productId: 0,
    product: undefined,
    categoryId: 0,
    frameNumber: '',
    serialNumber: '',
    billDate: moment(new Date()).startOf('day').unix(),
    purchasedPlaceId: 0,
    billPhoto: '',
    framePhotos: [],
    serialPhotos: [],
    bikeType: EUserProductType.Normal,
    customerId: user.customerId,
    type: ERepairshopType.Both,
  })
  const onSubmit = async (values: UserProductPostPut) => {
    setIsSubmit(true)
    try {
      const productData = await new ProductApi().apiProductsByProductIdGet(values.productId ?? 0)
      if (productData) {
        values.product = {
          brandId: productData.data.brandId ?? 0,
          categoryId: productData.data.categoryId ?? 0,
          name: productData.data.name ?? '',
          productId: productData.data.productId,
          itemNumber: values.product?.itemNumber,
          warrantyPeriod: productData.data.warrantyPeriod ?? 24,
          type: productData.data.type,
          brand: {
            brandId: productData.data.brandId,
          },
        }
      }
      if (!userProductId) {
        const _productType = values.product?.type === EProductType.Bicycle ? 1 : 2
        const type = getType(values.type)
        const bikeType = getBikeType(values.bikeType)

        await new UserProductApi().apiUserProductsPostWithForm(
          values.product?.name ?? '',
          values.product?.brandId ?? 0,
          values.product?.categoryId ?? 0,
          values.product?.warrantyPeriod ?? 0,
          values.customerId,
          values.productId,
          values.product?.productId,
          values.product?.brand?.brandId,
          values.product?.brand?.name,
          values.product?.brand?.isActive,
          values.product?.photo,
          values.product?.itemNumber,
          _productType,
          values.product?.year,
          values.product?.hidding,
          values.categoryId,
          values.frameNumber,
          values.serialNumber,
          values.billDate,
          values.billNumber,
          values.purchasedPlaceId,
          values.billPhoto,
          values.framePhotos,
          values.serialPhotos,
          values.isCancelled,
          values?.userProductId,
          values.batteryNumber,
          type,
          bikeType,
          values.photoUrl,
          values.caseId,
          values.isHaveOpenCase,
        )

        // await new UserProductApi().apiUserProductsPost(values)
      } else {
        await new UserProductApi().apiUserProductsByUserProductIdPut(Number(userProductId), values)
      }
      navigate('/my-bikes')
    } catch (ex) {
      dispatch(
        updateSnackbarState({
          visible: true,
          message: 'Error when add new bike, please check your bike information',
          type: 'error',
        } as any),
      )
    }
    setIsSubmit(false)
  }

  const getType = (type?: ERepairshopType) => {
    switch (type) {
      case ERepairshopType.Repairshop:
        return 0
      case ERepairshopType.Bikeshop:
        return 1
      case ERepairshopType.Both:
        return 2
      case ERepairshopType.OnlineShop:
        return 3
      default:
        return 0
    }
  }
  const getBikeType = (type?: EUserProductType) => {
    switch (type) {
      case EUserProductType.DemoBike:
        return 1
      case EUserProductType.EgorepBike:
        return 2
      case EUserProductType.Normal:
        return 0
      default:
        return 0
    }
  }

  const getValue = (list: any[], id?: number) => {
    const bike = list.find((bike) => {
      return String(bike.value) === String(id)
    })
    return bike ?? null
  }

  const { data } = useQuery<UserProductDetails>(
    [`/api/customerCases/details/${userProductId}`],
    async () => {
      const res = await new UserProductApi().apiUserProductsDetailsByUserProductIdGet(
        Number(userProductId),
      )
      return res.data as any
    },
    {
      enabled: Number(userProductId) > 0,
    },
  )
  useEffect(() => {
    if (data && user?.customerId && userProductId) {
      new ProductApi().apiProductsByProductIdGet(data.productId ?? 0).then((productData) => {
        setProduct({
          userProductId: Number(userProductId),
          brandId: data.brandId ?? 0,
          productId: data.productId,
          product: {
            brandId: productData.data.brandId ?? 0,
            categoryId: productData.data.categoryId ?? 0,
            name: productData.data.name ?? '',
            productId: productData.data.productId,
            warrantyPeriod: productData.data.warrantyPeriod ?? 24,
            type: productData.data.type,
            brand: {
              brandId: productData.data.brandId,
            },
          },
          categoryId: data.categoryId,
          frameNumber: data.frameNumber ?? '',
          serialNumber: data.serialNumber,
          billDate: data.billDate,
          purchasedPlaceId: data.purchasedPlace?.repairshopId,
          billPhoto: data.billPhoto,
          framePhotos: data.framePhotos,
          serialPhotos: data.serialPhotos,
          bikeType: data.bikeType,
          customerId: user?.customerId,
          type: ERepairshopType.Both,
        })
      })
    }
  }, [data, user?.customerId, userProductId])
  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - Register a new bike</title>
      </Helmet>
      <AppBar />
      <Container maxWidth={'md'}>
        <Stack
          direction='row'
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            my: 6,
          }}
        >
          <Box sx={{ width: 30 }}>
            <Link to='/'>
              <Icon name='icon-arrow-left' color='white' />
            </Link>
          </Box>
          <Box>
            <Typography variant='h3'>{t('registerABike')}</Typography>
          </Box>
          <Box sx={{ width: 30 }}></Box>
        </Stack>
        {showMessage && (
          <Alert
            sx={{ mb: 3, backgroundColor: Colors.primary }}
            severity='success'
            variant='filled'
            icon={false}
          >
            {t('registerBikeWarning')}
            <Stack direction={'row'} justifyContent='flex-end'>
              <a
                onClick={() => setShowMessage(false)}
                style={{ color: Colors.white, fontWeight: 600 }}
              >
                {t('dimiss')}
              </a>
            </Stack>
          </Alert>
        )}
        {isLoading && <Loading />}
        {!isLoading && (
          <Formik
            initialValues={product}
            validationSchema={bikeSchema}
            enableReinitialize={true}
            onSubmit={onSubmit}
          >
            {({ handleChange, setFieldValue, handleSubmit, values, errors, isValid }) => (
              <>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption' className='required'>
                    {t('brand')}
                  </Typography>
                  <TextField
                    select
                    fullWidth
                    value={values.brandId}
                    onChange={(value) => {
                      setFieldValue('brandId', value.target.value)
                    }}
                  >
                    {brands?.map((brand) => (
                      <MenuItem key={brand.value} value={brand.value}>
                        {brand.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {errors.brandId && (
                    <Typography className='textError'>{errors.brandId}</Typography>
                  )}
                </Box>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption' className='required'>
                    {t('category')}
                  </Typography>
                  <TextField
                    select
                    fullWidth
                    value={values.categoryId}
                    onChange={(event) => {
                      setFieldValue('categoryId', event.target.value)
                      const cate: any = categories?.find((c) => c.value === event.target.value)
                      setShowSerialNumber(cate?.showSerialNumber)
                    }}
                  >
                    {categories?.map((category) => (
                      <MenuItem key={category.value} value={category.value}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {errors.categoryId && (
                    <Typography className='textError'>{errors.categoryId}</Typography>
                  )}
                </Box>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption' className='required'>
                    {t('model')}
                  </Typography>
                  <Autocomplete
                    fullWidth
                    value={getValue(
                      getListBikes(values.brandId, values.categoryId) as any[],
                      values.productId,
                    )}
                    onChange={async (event, value) => {
                      setFieldValue('productId', value?.value)
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.value}>
                          {option.label}
                        </li>
                      )
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    options={getListBikes(values.brandId, values.categoryId) as any[]}
                    id='id'
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {errors.productId && (
                    <Typography className='textError'>{errors.productId}</Typography>
                  )}
                </Box>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption' className='required'>
                    {t('frameNumber')}
                  </Typography>
                  <TextField
                    value={values.frameNumber}
                    autoComplete='off'
                    onChange={(e) => {
                      setFieldValue('frameNumber', e.target.value?.toUpperCase())
                    }}
                    fullWidth
                    variant='outlined'
                  />
                  {errors.frameNumber && (
                    <Typography className='textError'>{errors.frameNumber}</Typography>
                  )}
                </Box>
                <Box sx={{ mb: 6 }}>
                  <FileInput
                    label={t('frameNumbersPhoto')}
                    multiple={true}
                    onChange={async (files) => {
                      if (files) {
                        setFieldValue('framePhotos', Array.from(files))
                      } else {
                        setFieldValue('framePhotos', [])
                      }
                    }}
                  />

                  {errors.framePhotos && (
                    <Typography className='textError'>{errors.framePhotos}</Typography>
                  )}
                </Box>
                {showSerialNumber && (
                  <>
                    <Box sx={{ mb: 6 }}>
                      <Typography variant='caption' className='required'>
                        {t('batteryNumber')}
                      </Typography>
                      <TextField
                        autoComplete='off'
                        value={values.serialNumber}
                        onChange={handleChange('serialNumber')}
                        fullWidth
                        variant='outlined'
                      />
                      <Typography variant='caption' fontSize={12}>
                        {t('batteryNumberDescription')}
                      </Typography>
                    </Box>
                    <Box sx={{ mb: 6 }}>
                      <FileInput
                        label={t('batteryNumberPhoto')}
                        multiple={true}
                        onChange={async (files) => {
                          if (files) {
                            setFieldValue('serialPhotos', Array.from(files))
                          } else {
                            setFieldValue('serialPhotos', [])
                          }
                        }}
                      />
                    </Box>
                  </>
                )}
                <Typography variant='h3' color={Colors.primary} sx={{ mb: 6, mt: 12 }}>
                  {t('billInformation')}
                </Typography>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption'>{t('purchasedDate')}</Typography>
                  <DatePicker
                    value={values.billDate ? moment.unix(values.billDate).toDate() : new Date()}
                    onChange={(date) => {
                      setFieldValue('billDate', moment(date).unix());
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth {...params}
                      />
                    )}
                    PaperProps={{
                      sx: {
                        '& .MuiPickersFadeTransitionGroup-root': {
                          color: 'black'
                        },
                      },
                    }}
                  />
                </Box>

                {!!values.brandId && (
                  <Box sx={{ mb: 6 }}>
                    <Typography variant='caption'>{t('purchasedPlace')}</Typography>
                    <Autocomplete
                      fullWidth
                      onChange={(event, value) => {
                        setFieldValue('purchasedPlaceId', value?.value)
                      }}
                      value={getValue(
                        getRepairShops(values.brandId) as any[],
                        values.purchasedPlaceId,
                      )}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.value}>
                            {option.label}
                          </li>
                        )
                      }}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader>{params.group}</GroupHeader>
                          <GroupItems style={{ padding: 0 }}>{params.children}</GroupItems>
                        </li>
                      )}
                      groupBy={(option) => option.type}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      options={getRepairShops(values.brandId) as any[]}
                      id='id'
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                )}
                <Box sx={{ mb: 6 }}>
                  <FileInput
                    label={t('billsPhoto')}
                    multiple={false}
                    accept={'image/*, .pdf'}
                    onChange={async (files) => {
                      if (files) {
                        setFieldValue('billPhoto', files[0])
                      } else {
                        setFieldValue('billPhoto', '')
                      }
                    }}
                  />
                  {errors.billPhoto && (
                    <Typography className='textError'>{errors.billPhoto}</Typography>
                  )}
                </Box>
                <Box sx={{ mb: 8 }}>
                  <Button
                    fullWidth
                    variant='contained'
                    disabled={isSubmit || !isValid}
                    onClick={() => handleSubmit()}
                  >
                    {t('save')}
                  </Button>
                </Box>
              </>
            )}
          </Formik>
        )}
      </Container>
    </Box>
  )
}

export default Add
