import React from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import {
  Root,
  Home,
  Auth,
  Login,
  AddBike,
  AddTicket,
  ListBike,
  ListTicket,
  DetailTicket,
  Profile,
  DetailBike,
  SignUp,
  Forgot,
  ResetPassword,
  Notification,
} from './pages'

import { Helmet } from 'react-helmet-async'
import { ContainerDialog } from './components'
import 'moment-timezone'

function App() {
  return (
    <div className='App'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Cykelmakker</title>
      </Helmet>
      <Routes>
        <Route path='/'>
          <Route path='auth' element={<Auth />}>
            <Route path='login' element={<Login />} />
            <Route path='forgot' element={<Forgot />} />
            <Route path='signup' element={<SignUp />} />
            <Route path='reset-password' element={<ResetPassword />} />
          </Route>
          <Route path='' element={<Root />}>
            <Route path='' element={<Home />} />
            <Route path='profile' element={<Profile />} />
            <Route path='notification' element={<Notification />} />

            <Route path='create-bike' element={<AddBike />} />
            <Route path='my-bikes' element={<ListBike />} />
            <Route path='bike' element={<DetailBike />} />

            <Route path='create-ticket' element={<AddTicket />} />
            <Route path='my-tickets' element={<ListTicket />} />
            <Route path='ticket' element={<DetailTicket />} />
          </Route>
        </Route>
      </Routes>
      <Outlet />
      <ContainerDialog />
    </div>
  )
}

export default App
