import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  CardHeader,
  Container,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Formik } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { AppBar, Icon, Loading, PaymentForm } from '../../components'
import { DATE_FORMAT, DATE_FORMAT_FULL } from '../../components/DatePicker/DatePicker'
import { PaymentProps } from '../../components/PaymentForm'
import {
  CustomerCaseApi,
  CustomerCaseDetails,
  CustomerCaseMessage,
  EMessageType,
  EPaymentStatus,
  MessageApi,
  MessagePostPut,
  PurchaseOrderItemDto,
} from '../../services/Swagger/Services'
import { Colors } from '../../theme'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { useTranslation } from 'react-i18next'
import { getTextAvatar } from '../../services/Helper'

export function urlify(text: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.replace(urlRegex, function (url) {
    return '<a target="_blank" href="' + url + '">' + url + '</a>'
  })
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

const messageSchema = yup.object({
  content: yup.string().required(),
})
const getTotal = (purchaseOrderItems: PurchaseOrderItemDto[]) => {
  if (purchaseOrderItems?.length < 1) return 0
  let sum = 0
  for (let i = 0; i < purchaseOrderItems.length; i++) {
    sum += purchaseOrderItems[i].total * purchaseOrderItems[i].quantity
  }
  return sum
}

function Detail() {
  const { t } = useTranslation()
  const [paymentModal, setPaymentModal] = useState<PaymentProps & { visible: boolean }>({
    visible: false,
    amount: 0,
    currency: 'DKK',
    purchaseOrderId: 0,
    purchaseOrderCode: '',
  })
  const [searchParams] = useSearchParams()
  const caseId = searchParams.get('id')
  const { data, isLoading, refetch } = useQuery<CustomerCaseDetails & { userProduct: any }>(
    [`/api/customerCases/details/${caseId}`],
    async () => {
      const res = await new CustomerCaseApi().apiCustomerCasesDetailsByCaseIdGet(
        Number(searchParams.get('id')),
      )
      const mesageL = res.data?.messages
      mesageL?.sort((a, b) => {
        return (b.createdDate ?? 0) - (a.createdDate ?? 0)
      })
      setMessageList(mesageL)
      return res.data as any
    },
  )
  const [isSubmit, setIsSubmit] = useState(false)
  const [lightBox, setLightBox] = useState({
    visible: false,
    src: '',
  })

  const [messageList, setMessageList] = useState<CustomerCaseMessage[]>()
  const [message, setMessage] = useState<MessagePostPut>({
    type: EMessageType.Public,
    content: '',
    caseId: Number(caseId),
    files: [],
    fileNames: [],
  })
  const onPaySuccess = () => {
    setPaymentModal({
      ...paymentModal,
      visible: false,
    })
    refetch()
  }
  const saveMessage = async (values: MessagePostPut) => {
    if (!values.content) return
    setIsSubmit(true)
    await new MessageApi().apiMessagesPost(values)
    setMessage({
      ...values,
      content: '',
    })
    refetch()
    setIsSubmit(false)
  }

  useEffect(() => {
    if (caseId) {
      setTimeout(() => {
        new MessageApi().apiMessagesReadAllMessagesByCaseIdPut(Number(caseId))
      }, 200)
    }
  }, [caseId])
  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('ticketInfo')}</title>
      </Helmet>
      <AppBar />
      <Container maxWidth={'lg'}>
        <Box justifyContent={'space-between'} display='flex' alignItems={'center'} sx={{ mt: 6 }}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link to='/'>Cykelmakker</Link>
            <Link to='/my-tickets'>{t('myTickets')}</Link>
            <Typography color='text.primary'>{data?.subject?.substring(0, 100)}</Typography>
          </Breadcrumbs>
        </Box>
        {isLoading && <Loading />}
        {!isLoading && (
          <Box>
            <Stack
              className='borderBottom'
              direction={'row'}
              alignItems='center'
              sx={{ my: 6 }}
              spacing={4}
            >
              <Icon name='icon-info-circle' color={Colors.primary} />
              <Typography color={Colors.primary} variant='h3'>
                {t('ticketInfo')}
              </Typography>
            </Stack>
            <Grid container columns={12} spacing={4}>
              <Grid item xs={3}>
                <Typography variant='body2'>{t('Status')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography align='right'>{data?.status}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant='body2'>{t('ticketSubject')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography align='right'>{data?.subject}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant='body2'>{t('submittedDate')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography align='right'>
                  {moment
                    .unix(data?.caseInformationOverView?.createdDate ?? 0)
                    .format(DATE_FORMAT_FULL)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant='body2'>{t('bike')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography align='right'>{data?.caseInformationOverView?.productName}</Typography>
              </Grid>
              {data?.warrantyDate != null ? (
                <>
                  <Grid item xs={3}>
                    <Typography variant='body2'>{t('warrantyUntil')}</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography align='right'>
                      {moment.unix(data?.warrantyDate ?? 0).format(DATE_FORMAT)}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={3}>
                <Typography variant='body2'>{t('servicePlace')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography align='right'>
                  {data?.caseInformationOverView?.repairShopName}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant='body2'>{t('description')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography align='right'>{data?.caseInformationOverView?.description}</Typography>
              </Grid>
              <Grid item xs={12}>
                {data?.caseInformationOverView?.photos &&
                  data?.caseInformationOverView?.photos.length > 0 && (
                    <Grid direction='row' spacing={4} container columns={12} sx={{ mt: 1 }}>
                      {data?.caseInformationOverView?.photos.map((file, index) => {
                        return (
                          <Grid item xs={4} key={index}>
                            <Box
                              className='boxPreviewFile'
                              alignItems={'center'}
                              justifyContent='space-between'
                            >
                              <a
                                onClick={() =>
                                  setLightBox({
                                    visible: true,
                                    src: file,
                                  })
                                }
                              >
                                {file.includes('.mp4') ? (
                                  <video controls>
                                    <source src={file} type='video/mp4' />
                                  </video>
                                ) : (
                                  <img src={file} alt='previewImage' />
                                )}
                              </a>
                            </Box>
                          </Grid>
                        )
                      })}
                    </Grid>
                  )}
              </Grid>
            </Grid>
            {data?.purchaseOrders && data?.purchaseOrders.length > 0 && (
              <>
                <Stack
                  className='borderBottom'
                  direction={'row'}
                  alignItems='center'
                  sx={{ my: 6 }}
                  spacing={4}
                >
                  <Icon name='icon-card' color={Colors.primary} />
                  <Typography color={Colors.primary} variant='h3'>
                    {t('payment')}
                  </Typography>
                </Stack>
                {data.purchaseOrders.map((purchaseOrder) => {
                  return (
                    <Grid
                      sx={{ py: 1 }}
                      container
                      columns={12}
                      key={purchaseOrder.purchaseOrderId}
                      alignItems='center'
                    >
                      <Grid item xs={4}>
                        <Typography variant='body2'>#{purchaseOrder.purchaseOrderId}</Typography>
                      </Grid>
                      <Grid item xs={4} textAlign='right'>
                        <Typography variant='body2'>
                          {getTotal(purchaseOrder.purchaseOrderItems)} {purchaseOrder.currency}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} textAlign='right'>
                        {purchaseOrder.status === EPaymentStatus.Paid && (
                          <Typography>{t('paid')}</Typography>
                        )}
                        {purchaseOrder.status === EPaymentStatus.Pending && (
                          <Button
                            variant='contained'
                            disabled={isSubmit}
                            size='small'
                            onClick={() => {
                              setPaymentModal({
                                visible: true,
                                amount: getTotal(purchaseOrder.purchaseOrderItems),
                                currency: purchaseOrder.currency,
                                purchaseOrderCode: purchaseOrder.purchaseOrderCode,
                                purchaseOrderId: purchaseOrder.purchaseOrderId,
                              })
                            }}
                          >
                            {t('payNow')}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  )
                })}
              </>
            )}

            <Stack
              className='borderBottom'
              direction={'row'}
              alignItems='center'
              sx={{ my: 6 }}
              spacing={4}
            >
              <Icon name='icon-messages-3' color={Colors.primary} />
              <Typography color={Colors.primary} variant='h3'>
                {t('message')}
              </Typography>
            </Stack>
            <Formik
              initialValues={message}
              enableReinitialize={true}
              validationSchema={messageSchema}
              onSubmit={saveMessage}
            >
              {({ handleChange, handleSubmit, values, errors }) => (
                <>
                  <Box sx={{ mb: 6 }}>
                    <TextField
                      value={values.content}
                      onChange={handleChange('content')}
                      placeholder={t('typeAMessage')}
                      fullWidth
                      multiline
                      variant='outlined'
                    />
                    {errors.content && (
                      <Typography className='textError'>{errors.content}</Typography>
                    )}
                  </Box>
                  <Box sx={{ mb: 8 }} display='flex' justifyContent='space-between'>
                    <Button
                      variant='contained'
                      disabled={isSubmit}
                      size='small'
                      onClick={() => handleSubmit()}
                    >
                      {t('done')}
                    </Button>
                  </Box>
                </>
              )}
            </Formik>
            <Box>
              {messageList &&
                messageList.length > 0 &&
                messageList?.map((message) => {
                  return (
                    <Box key={message.messageId} sx={{ mb: 6 }} className='borderTop'>
                      <CardHeader
                        sx={{ p: 0, mb: 4 }}
                        avatar={
                          <Avatar
                            sx={{ bgcolor: message.color ?? Colors.primary }}
                            aria-label='recipe'
                          >
                            {getTextAvatar(message.fullName ?? '')}
                          </Avatar>
                        }
                        title={message.fullName}
                        subheader={moment.unix(message.createdDate ?? 0).format(DATE_FORMAT_FULL)}
                      />
                      <div
                        className='messageContent'
                        dangerouslySetInnerHTML={{ __html: urlify(message.content ?? '') }}
                      />
                      {message.files && message.files.length > 0 && (
                        <Grid direction='row' spacing={4} container columns={12} sx={{ mt: 1 }}>
                          {message.files.map((file, index) => {
                            return (
                              <Grid item xs={4} key={index}>
                                <Box
                                  className='boxPreviewFile'
                                  alignItems={'center'}
                                  justifyContent='space-between'
                                >
                                  <a
                                    onClick={() =>
                                      setLightBox({
                                        visible: true,
                                        src: file,
                                      })
                                    }
                                  >
                                    <img src={file} alt='previewImage' />
                                  </a>
                                </Box>
                              </Grid>
                            )
                          })}
                        </Grid>
                      )}
                    </Box>
                  )
                })}
              {(!messageList || messageList.length == 0) && (
                <Box sx={{ mb: 6 }}>
                  <Typography variant='body2'>{t('noData')}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Container>
      {paymentModal.visible && (
        <Modal
          open={paymentModal.visible}
          onClose={() => setPaymentModal({ ...paymentModal, visible: false })}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={{ mt: 6, p: 6, borderRadius: 4, backgroundColor: 'white', width: 400 }}>
            <Typography sx={{ color: 'black', mb: 4 }} variant='h3'>
              {t('payment')}
            </Typography>
            <PaymentForm {...paymentModal} onSuccess={onPaySuccess} />
          </Box>
        </Modal>
      )}
      {lightBox.visible && (
        <Lightbox
          mainSrc={lightBox.src ?? ''}
          onCloseRequest={() =>
            setLightBox({
              visible: false,
              src: '',
            })
          }
        />
      )}
    </Box>
  )
}

export default Detail
